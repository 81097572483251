import React from 'react'

export default function Case8() {
  return (
    <>
    <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>


<i style={{fontSize:  "17px"}}>Case Title:  INDIAN SPINAL INJURIS CENTRE vs M/S GALAXY INDIA</i>        
<i style={{fontSize:  "17px"}}>Court:  Delhi High Court</i>
<i style={{fontSize:  "17px"}}>Date of Judgement:  08.05.2024</i>
    </div>
    
<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>In the captioned case, the Hon’ble Delhi High Court dealt with a petition under Section 11(6) read with Section 11(8) of the Arbitration and Conciliation Act, 1996 (the “Act”) filed by Indian Spinal Injuries Centre (“Petitioner”) seeking the appointment of an Arbitral Tribunal to adjudicate disputes with M/S Galaxy India (“Respondent”).</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The key issue was whether the notice under Section 21 of the Act, which is a prerequisite for the commencement of arbitration proceedings, was properly served.</p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The Respondent contended that the notice under Section 21 of the Act was not properly served, as it was sent to an incomplete address. According to the Respondent, the proper address as per the agreement was T-5/237, Mangol Puri Industrial Area, Phase-I, New Delhi-110083, whereas the notice was sent to T-5, Mangol Puri Industrial Area, Phase I, New Delhi.</p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The Court emphasized that the service of notice under Section 21 of the Act is a mandatory prerequisite for the commencement of arbitration proceedings, as established in previous judgments, including Alupro Building Systems Pvt. Ltd. v. Ozone Overseas Pvt. Ltd.2 which was followed in Amit Guglani & Anr. v. L and T Housing Finance Ltd. Through Managing Director & Anr.3 Furthermore, the ratio of these judgments also makes it clear that merely sending the notice under Section 21 of the Act is not sufficient and there must be a receipt of the notice.</p>       

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>The Hon’ble Court therefore held that the incomplete service of notice resulted in non-compliance with the mandatory provision i.e. Section 21 of the Act. Consequently, the petition was dismissed.</p>           
</>
  )
}
