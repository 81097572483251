import React from "react";

export default function Case25() {
  return (
    <>
      
      <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>

    <i style={{fontSize:  "17px"}}>Case Title: MS Retail Private Limited v. Ugro Capital</i>
    <i style={{fontSize:  "17px"}}>Court: Addl. City Civil and Sessions Judge (Commercial Court) Bengaluru</i>
    <i style={{fontSize:  "17px"}}>Date of Judgment: 5.09.2022</i>
</div>

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",color: "#383838"}}>
In the case of MS Retail Private Limited v. Ugro Capital2, the mandate of an arbitrator who was
appointed by Presolv360 was challenged. The Hon’ble Court held that since no circumstances
which are specified in the Seventh Schedule of Arbitration and Conciliation Act, 1996 could be
shown, the appointment by Presolv360 is valid and cannot be held to be ineligible.
</p>

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838"}}>
   
The court specifically in para 20 of the judgment stated that “<em>The applicant though has
sought for termination of the arbitrator's mandate on the ground of ineligibility, none of the
circumstances as specified in the Seventh Schedule are shown to have been existing.
Therefore, the sole arbitrator appointed through ODR platform, per se, cannot be held to be
ineligible under Section 12 (5) of the Act.</em> ”
</p>
   </>
  );
}
