import React from 'react'

export default function Case9() {
  return (
    <>          
<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>We are pleased to inform you about a significant development in the judicial treatment of awards passed by arbitrators appointed by Online Dispute Resolution (“ODR”) institutions like Presolv360. Recent judicial trends indicate a robust inclination towards dismissing challenges against arbitral awards, and towards enforcing awards issued by arbitrators appointed by ODR Institutions. Interestingly, such judgements are particularly in relation to those agreements which had unilateral appointment clauses.</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>A recent article published by Bar and Bench, titled “ODR: A method to solve the unilateral arbitrator appointment conundrum?” highlights these judicial trends. The article cites several court judgments where arbitral awards administered by ODR institutions were upheld, and challenges based on unilateral arbitrator appointments were rejected. This trend marks a significant shift in the legal landscape, enhancing the credibility and reliability of ODR mechanisms. It reflects a growing recognition of ODR’s potential to resolve disputes efficiently and effectively, aligning with modern judicial principles.</p>     

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>We encourage you to familiarize yourself with this article to understand the implications of these trends better. It is crucial to understand these to ensure that we are aligned with the latest judicial standards and practices. Imperatively, this development is not only a testament to the robustness of ODR frameworks but also an essential factor in strategic planning and execution with respect to dispute resolution.</p>
   </>
  )
}
