import React, { useState } from "react";
import Pagination from "../../components/Pagination";
import CaseAlertImage from "../../assets/Images/case alerts images.png";
import AnimatedBox from "../../components/AnimatedBox";
import DynamicModal from "../../components/DynamicModel/DynamicModal";
import Case1 from "../../components/AllCase/Case1";
import Case2 from "../../components/AllCase/Case2";
import Case3 from "../../components/AllCase/Case3";
import Image1 from "../../assets/Images/caseAlertImages/Case1.png";
import Image2 from "../../assets/Images/caseAlertImages/Case2.png";
import Image3 from "../../assets/Images/caseAlertImages/Case3.png";
import Image4 from "../../assets/Images/caseAlertImages/Case4.png";
import Image5 from "../../assets/Images/caseAlertImages/Case5.png";
import Image6 from "../../assets/Images/caseAlertImages/Case6.png";
import Image7 from "../../assets/Images/caseAlertImages/Case7.png";
import Image8 from "../../assets/Images/caseAlertImages/Case8.png";
import Image9 from "../../assets/Images/caseAlertImages/Case9.png";
import Image10 from "../../assets/Images/caseAlertImages/Case10.png";
import Image11 from "../../assets/Images/caseAlertImages/Case11.png";
import Image12 from "../../assets/Images/caseAlertImages/Case12.png";
import Image13 from "../../assets/Images/caseAlertImages/Case13.png";
import Image14 from "../../assets/Images/caseAlertImages/Case14.png";
import Image15 from "../../assets/Images/caseAlertImages/Case15.png";
import Image16 from "../../assets/Images/caseAlertImages/Case16.png";
import Image17 from "../../assets/Images/caseAlertImages/Case17.png";
import Image18 from "../../assets/Images/caseAlertImages/Case18.png";
import Image19 from "../../assets/Images/caseAlertImages/Case19.png";
import Image20 from "../../assets/Images/caseAlertImages/Case20.png";
import Image21 from "../../assets/Images/caseAlertImages/Case21.png";
import Image22 from "../../assets/Images/caseAlertImages/Case22.png";
import Image23 from "../../assets/Images/caseAlertImages/Case23.png";
import Image24 from "../../assets/Images/caseAlertImages/Case24.png";
import Image25 from "../../assets/Images/caseAlertImages/Case25.png";
import Image26 from "../../assets/Images/caseAlertImages/Case26.png";
import Image27 from "../../assets/Images/caseAlertImages/Case27.png";
import Image28 from "../../assets/Images/caseAlertImages/Case28.png";
import Case4 from "../../components/AllCase/Case4";
import Case5 from "../../components/AllCase/Case5";
import Case6 from "../../components/AllCase/Case6";
import Case7 from "../../components/AllCase/Case7";
import Case8 from "../../components/AllCase/Case8";
import Case9 from "../../components/AllCase/Case9";
import Case10 from "../../components/AllCase/Case10";
import Case11 from "../../components/AllCase/Case11";
import Case12 from "../../components/AllCase/Case12";
import Case13 from "../../components/AllCase/Case13";
import Case14 from "../../components/AllCase/Case14";
import Case15 from "../../components/AllCase/Case15";
import Case16 from "../../components/AllCase/Case16";
import Case17 from "../../components/AllCase/Case17";
import Case18 from "../../components/AllCase/Case18";
import Case19 from "../../components/AllCase/Case19";
import Case20 from "../../components/AllCase/Case20";
import Case21 from "../../components/AllCase/Case21";
import Case22 from "../../components/AllCase/Case22";
import Case23 from "../../components/AllCase/Case23";
import Case24 from "../../components/AllCase/Case24";
import Case25 from "../../components/AllCase/Case25";
import Case26 from "../../components/AllCase/Case26";
import Case27 from "../../components/AllCase/Case27";
import Case28 from "../../components/AllCase/Case28";

const CaseData = [
  {
    tittle:
      "Jurisdiction of the arbitral tribunal cannot be challenged after submission of the statement of defence",
    description:
      "Section 16 of the Act, in line with the kompetenz-kompetenz principle, provides that the arbitral tribunal is competent to rule on its jurisdiction. The intent of the said provision is to ensure minimal court intervention in arbitration proceedings. While Section 16 of the Act allows parties to challenge the jurisdiction of an arbitral tribunal, sub-section (2) bars a party from raising such a challenge after submission of the statement of defence.",
    Link: "https://api.sci.gov.in/supremecourt/2021/4339/4339_2021_5_21_58313_Judgement_07-Jan-2025.pdf",
    image: Image1,
    caseNumber: 1,
  },

  {
    tittle:
      "Section 12(5) Requires Proof of Seventh Schedule Relationship; Challenge to Unilateral Appointment After Full Participation and Section 29A Extension Barred: Madras HighCourt",
    description:
      "In an interesting development after the Constitution Bench judgment on the issue of Unilateral Appointment in Central Organisation for Railway Electrification v. ECI-SPIC-SMO-MCML (JV)1 (“CORE”), the Madras High Court in in the captioned case addressed crucial questions regarding challenges to arbitrator appointments and waiver of objections under the Arbitration and Conciliation Act, 1996 (“Act”).",
    Link: "https://drive.google.com/file/d/1lHTp_rBFgbQ7ZqC0kpL2fUDBtgmZTN7T/view",
    image: Image2,
    caseNumber: 2,
  },

  {
    tittle:
      "Institutional Arbitration is the way forward after the Supreme Court of India authoritatively strikes down Unilateral Appointment of Arbitrators",
    description:
      "The 5-Judge Constitution Bench of the Supreme Court of India, in Central Organisation for Railway Electrification v. M/s ECI SPIC SMO MCML (JV), struck down unilateral appointment of arbitrators, for being violative of the principles of fairness, impartiality and equality in arbitration proceedings.",
    Link: "https://drive.google.com/file/d/1heyAo0Vigg8MmW34_lciVGQlmG3z9KTN/view",
    image: Image3,
    caseNumber: 3,
  },

  {
    tittle:
      "Enablement of Online Dispute Resolution through the Draft Arbitration and Conciliation (Amendment) Bill, 2024",
    description:
      "The Draft Arbitration and Conciliation (Amendment) Bill, 2024 (“Bill”) proposes several changes to the existing Arbitration and Conciliation Act, 1996 (“Act”). Among these amendments, there are provisions that are particularly relevant to Online Dispute Resolution (“ODR”).",
    image: Image4,
    caseNumber: 4,
  },
  {
    tittle:
      "Bombay High Court: Arbitrator Can Change Venue Even if Specified in Agreement",
    description:
      "The Bombay High Court, in a significant ruling, has held that an arbitrator has the authority to change the venue of arbitration proceedings to a more convenient location, even if a specific venue is mentioned in the agreement. The Court emphasized that this change is permissible if conducting proceedings at the agreed venue would be detrimental to the arbitration process.",
    Link: "https://www.livelaw.in/pdf_upload/document-566578.pdf",
    image: Image5,
    caseNumber: 5,
  },
  {
    tittle:
      "Court dismissed challenges and confirms award in arbitration proceedings administered by Presolv360",
    description:
      "In a significant ruling, the Jaipur Commercial Court dismissed objections filed under Section 34 of the Arbitration and Conciliation Act, 1996 (“Act”) challenging an arbitral award in an arbitration administered by Presolv360 ODR Institution.",
    Link: "https://drive.google.com/file/d/1DoevqaP0UWvtxa9f4fCPUNeJK3Dk3_0Q/view",
    image: Image6,
    caseNumber: 6,
  },
  {
    tittle:
      "Delhi Court Upholds Arbitral Award, Validates Appointment through Presolv360",
    description:
      "A Delhi Commercial Court has dismissed objections filed under Section 34 of the Arbitration and Conciliation Act, 1996 (“Act”) challenging an arbitral award passed by an arbitrator appointed by Presolv360. The objections were based on alleged unilateral appointment of the arbitrator and lack of service of notice under Section 21 of the Act to the petitioners.",
    Link: "https://drive.google.com/file/d/1CE2_jlAylfFuTOonH28vZxx73chxOwZu/view",
    image: Image7,
    caseNumber: 7,
  },
  {
    tittle:
      "Sending Section 21 Notice under A&C Act To An Incomplete Address Results in Dismissal of Petition Under Section 11",
    description:
      "In the captioned case, the Hon’ble Delhi High Court dealt with a petition under Section 11(6) read with Section 11(8) of the Arbitration and Conciliation Act, 1996 (the “Act”) filed by Indian Spinal Injuries Centre (“Petitioner”) seeking the appointment of an Arbitral Tribunal to adjudicate disputes with M/S Galaxy India (“Respondent”).",
    Link: "https://www.livelaw.in/pdf_upload/indian-spinal-injuries-centre-vs-ms-galaxy-india-544248.pdf",
    image: Image8,
    caseNumber: 8,
  },
  {
    tittle:
      "UPDATE: JUDICIARY’S RECENT TRENDS FAVOURING AWARDS PASSED BY ARBITRATORS APPOINTED BY ODR INSTITUTIONS - PRESOLV360 - UPDATE",
    description:
      "We are pleased to inform you about a significant development in the judicial treatment of awards passed by arbitrators appointed by Online Dispute Resolution (“ODR”) institutions like Presolv360. Recent judicial trends indicate a robust inclination towards dismissing challenges against arbitral awards, and towards enforcing awards issued by arbitrators appointed by ODR Institutions. Interestingly, such judgements are particularly in relation to those agreements which had unilateral appointment clauses.",
    Link: "https://www.barandbench.com/columns/odr-a-method-to-solve-the-unilateral-arbitrator-appointment-conundrum",
    image: Image9,
    caseNumber: 9,
  },
  {
    tittle:
      "Arbitration Clause in Hyperlinked Terms and Conditions Incorporated by Reference: Delhi High Court",
    description:
      "In this case, the Hon’ble Delhi High Court dealt with cross-appeals arising from an order of a Commercial Court rejecting an application under Section 8 of the Arbitration and Conciliation Act, 1996 (the “Act”) filed to refer parties to arbitration in a suit. The key issue was whether the arbitration clause in the Terms and Conditions published on a website was incorporated into the Marketing and Operational Consulting Agreement (“MOCA”) between the parties by reference under a particular clause of the MOCA.",
    Link: "https://www.livelaw.in/high-court/delhi-high-court/delhi-high-court-arbitration-clause-terms-conditions-hyperlink-website-agreement-256420",
    image: Image10,
    caseNumber: 10,
  },

  {
    tittle:
      "Challenge to Arbitral Award passed by Arbitrator appointed by Presolv360 Rejected by Delhi Court",
    description:
      "In a significant ruling, the Delhi Commercial Court dismissed objections filed under Section 34 of the Arbitration and Conciliation Act, 1996 (“Act”) challenging an arbitral award on grounds of alleged unilateral appointment of the arbitrator and lack of proper notice to the petitioner.",
    Link: "https://drive.google.com/file/d/1XkLglQBmCpZSlDKk0gcV2Yf4OPYMbB9j/view",
    image: Image11,
    caseNumber: 11,
  },
  {
    tittle:
      "High Court Upholds Unilateral Arbitrator Appointment, Deems Post-Award Challenge Untenable",
    description:
      "The Hon’ble Delhi High Court highlighted the importance of following proper procedures when objecting to the appointment of an arbitrator under an arbitration agreement. The court upheld the rejection of a challenge under Section 34 of the Arbitration and Conciliation Act, 1996 (“Act”) against an arbitral award, where the arbitrator was appointed unilaterally as per the arbitration clause.",
    Link: "https://drive.google.com/file/d/1n6bGHV0k-a1U6BmP-_-R1_lC9yCqb5ti/view",
    image: Image12,
    caseNumber: 12,
  },
  {
    tittle:
      "Delhi High Court allows Section 11 Petition Based on Email and WhatsApp Service",
    description:
      "The Hon’ble Delhi High Court allowed a petition filed under Section 11 of the Arbitration and Conciliation Act, 1996 (“A&C Act”), referring the dispute to the Delhi International Arbitration Centre (“DIAC”) to appoint the arbitrator and adjudicate disputes between the parties. This decision came after the respondents failed to appear despite being served through email and WhatsApp as per the contact details mentioned in the underlying Lease Agreement.",
    Link: "https://www.livelaw.in/high-court/delhi-high-court/delhi-high-court-service-whatsapp-email-address-valid-255054",
    image: Image13,
    caseNumber: 13,
  },

  {
    tittle:
      "Providing Wrong Address Bars Objection to Arbitration Notice: Delhi High Court",
    description:
      "In a notable decision, the Hon’ble Delhi High Court addressed a jurisdictional issue involving the appointment of an arbitrator in a dispute arising from a partnership deed. The respondent objected to the petitioner's application under Section 11 of the Arbitration and Conciliation Act, 1996, (“Act”) contending that the invocation notice under Section 21 of the Act was not served at the correct address, rendering the petition premature.",
    Link: "https://www.livelaw.in/arbitration-cases/party-providing-wrong-address-during-proceedings-cannot-argue-incorrect-arbitration-notice-us-21-ac-delhi-high-court-250572?from-login=118249",
    image: Image14,
    caseNumber: 14,
  },

  {
    tittle:
      "ODR-administered Arbitration finds Acceptance in Execution Proceedings Despite Unilateral Arbitrator Appointment Clause in the Agreement",
    description:
      "The Commercial Court in Saket, New Delhi, on 5th January 2024, dealt with an execution petition based on an arbitration award in a dispute administered by Presolv360 ODR Institution. The pivotal issue was the unilateral arbitrator appointment clause in the agreement. The Court was intimated that the appointment of an arbitrator was not unilateral.",
    Link: "https://drive.google.com/file/d/1ZOCqYK7K4mBnu3oUPc2HqSymLvNzOshG/view",
    image: Image15,
    caseNumber: 15,
  },

  {
    tittle:
      "Challenge: Non-executable arbitral awards due to unilateral arbitrator appointments Solution: Institutional Arbitration through Online Dispute Resolution (ODR) Platform",
    description:
      "The Supreme Court of India dismissed a Special Leave Petition filed against the Delhi High Court’s decision, which upheld a Commercial Court's refusal to execute an award passed by a unilaterally appointed arbitrator. The Hon’ble Supreme Court noted the ineligibility of the arbitrator appointed unilaterally by the petitioner as per Section 12(5) of the Arbitration and Conciliation Act, 1996 (“Act”) and thereby found no grounds for interference under Article 136 of the Constitution of India, leading to the dismissal of the petition.",
    Link: "https://drive.google.com/file/d/1OLGGFlYQP7yKeUOId7lbHRd5-9R1e3cM/view",
    image: Image16,
    caseNumber: 16,
  },

  {
    tittle:
      "Bombay High Court Addresses Jurisdictional Challenge in Arbitration Involving a Financial Institution",
    description:"In a notable decision, the Hon’ble Bombay High Court addressed a fundamental jurisdictional challenge in arbitration proceedings involving a financial institution. The respondents contested the court’s jurisdiction to entertain petitions filed under Section 9 of the Arbitration and Conciliation Act, 1996, and an application under Section 11, arguing that as a “financial institution” under the SARFAESI Act, the petitioner should proceed under the SARFAESI Act, barring the recourse to arbitration.",
    Link: "https://drive.google.com/file/d/1OCp9D31b46g4D3Bew48ZSRv-AmNvu17Q/view",
    image: Image17,
    caseNumber: 17,
  },
  {
    tittle:
    "Kerala High Court Rules on Strict Adherence to Limitation Period in Arbitration Cases as per Arbitration and Conciliation Act, 1996",
  description:
    "In the case of Mathew P J v. M/S. Cholamandalam Investment And Finance Co Ltd2 the Hon’ble Kerala High Court has made a crucial ruling regarding the limitation period for challenging arbitral awards. The Hon’ble Kerala High Court has held that Section 5 of the Limitation Act 1963 (“Limitation Act”) is inapplicable for condoning delays in filing applications under Section 34 of the Arbitration and Conciliation Act 1996 (“Arbitration Act”) since the Arbitration Act is a special statute.",
  Link: "https://www.livelaw.in/high-court/kerala-high-court/kerala-high-court-section-5-limitation-act-arbitration-act-specified-period-limitation-245076?infinitescroll=1",
  image: Image18,
  caseNumber: 18,
  },

  {
    tittle:
      "Supreme Court of India Upholds Enforceability of Arbitration Clauses in Unstamped Agreements, Overruling Previous Precedents",
    description: `The Supreme Court of India, led by a seven-judge bench, made a significant ruling on December 13, 2023, declaring that arbitration clauses in unstamped or inadequately stamped agreements are enforceable. This decision overrules the earlier judgment by a five-judge bench in the case of <i>M/s. N.N. Global Mercantile Pvt. Ltd. v. M/s. Indo Unique Flame Ltd. And Ors,</i> which held that unstamped arbitration agreements were not enforceable.`,
    Link: "https://www.livelaw.in/top-stories/arbitration-clauses-in-unstamped-agreements-enforceable-supreme-court-7-judge-bench-overruled-nn-global-decision-244387?infinitescroll=1",
    image: Image19,
    caseNumber: 19,
  },

  {
    tittle:
      "Delhi High Court Upholds Coexistence of Arbitration and Section 138 Proceedings in Dishonoured Cheque Case",
    description: `The Hon’ble Delhi High Court ruled that arbitration and Section 138 proceedings arise from separate causes of action and can coexist. This decision was influenced by the Supreme Court's ruling in <i>Sri Krishna Agencies v. State of A.P. & Anr</i> affirming the simultaneous continuance of criminal and civil proceedings for separate causes.`,
    Link: "http://livelaw.in/pdf_upload/negotiable-instruments-506816.pdf",
    image: Image20,
    caseNumber: 20,
  },

  {
    tittle:
      "Delhi High Court Rules Arbitral Tribunal cannot create Security on Already Charged Property",
    description:
      "The Hon’ble Delhi High Court ruled that an arbitral tribunal cannot create security on a property over which a charge is already created in favour of a third party. This decision came after the tribunal, under Section 17 of the Arbitration and Conciliation Act (“A&C Act”), issued an interim order creating security over a property. The appellant, who was not a party to the arbitration agreement, challenged this order, arguing that they had a pre-existing, registered charge on the property. The respondents claimed they had invested in projects with secured interests prior to the appellant's charge.",
    Link: "https://www.livelaw.in/high-court/delhi-high-court/arbitral-tribunal-has-no-jurisdiction-to-create-security-over-a-property-which-has-a-third-party-charge-delhi-high-court-243229",
    image: Image21,
    caseNumber: 21,
  },

  {
    tittle:
      "Bombay High Court Addresses Issues of Non-Stamping in Arbitration Agreements",
    description:
      "On 25.10.2023, the Hon’ble Bombay High Court observed that objections regarding the non-stamping or insufficient stamping of an arbitration agreement need to be raised at the earliest before the arbitrator or at the first available opportunity. If not raised, such objections cannot be entertained later under Sections 34 or 37 of the Arbitration and Conciliation Act, 1996 (“Act”)",
    Link: "https://www.livelaw.in/high-court/bombay-high-court/bombay-high-court-arbitral-award-challenged-appeal-new-grounds-241706?infinitescroll=1",
    image: Image22,
    caseNumber: 22,
  },

  {
    tittle:
      "Insufficient Stamping of Arbitration Agreement Not Sole Ground for Setting Aside  Award: Delhi High Court",
    description:
      "Recently, the Hon’ble Delhi High Court held that an arbitration award cannot be set aside solely because the arbitration agreement was insufficiently stamped as per the requirements of the Stamp Act. The insufficient stamping of the agreement is only a violation of the Stamp Act requirements and does not by itself provide grounds for setting aside the resultant award.",
    Link: "https://www.livelaw.in/high-court/delhi-high-court/delhi-high-court-insufficiently-stamped-agreement-is-only-against-stamp-act-cant-be-a-ground-to-set-aside-award-233893",
    image: Image23,
    caseNumber: 23,
  },


  {
    tittle:
      "Insights from the Hon'ble Calcutta High Court Assessing the Parameters for Arbitrator’s Disqualification",
    description:
      "The Hon’ble Calcutta High Court while deciding on a Section 14 application filed under the Arbitration and Conciliation Act, 1996, (“Act”) held that all unilateral appointment of arbitrators is not invalid unless the arbitrator’s relationship falls within the Seventh Schedule to the Arbitration and Conciliation Act, 1996.",
    Link: "https://www.barandbench.com/columns/the-calcutta-high-courts-decision-in-mcleod-russel-unsettling-the-post-perkins-jurisprudence-on-unilateral-appointment-of-arbitrators",
    image: Image24,
    caseNumber: 24,
  },

  {
    tittle: "Appointment of Arbitrator by Presolv360 held to be valid",
    description:
      "In the case of MS Retail Private Limited v. Ugro Capital2, the mandate of an arbitrator who was appointed by Presolv360 was challenged. The Hon’ble Court held that since no circumstances which are specified in the Seventh Schedule of Arbitration and Conciliation Act, 1996 could be shown, the appointment by Presolv360 is valid and cannot be held to be ineligible.",
    Link: "https://drive.google.com/file/d/1OoHLX51SHFz3JtdTTXip4EdcqjwUbbxw/view?usp=sharing",
    image: Image25,
    caseNumber: 25,
  },

  {
    tittle:
      "Supreme Court of India Refers Issue of Enforceability of Unstamped Arbitration Agreements to Seven-Judge Bench",
    description: `The Supreme Court of India has referred the question of whether unstamped or insufficiently stamped arbitration agreements are enforceable to a seven-judge bench. This decision was made by a five-judge bench led by the Chief Justice of India, DY Chandrachud, while hearing a curative petition in the case <i>of Bhaskar Raju and Brothers and Anr V. s Dharmaratnakara Rai Bahadur Arcot Narainswamy Mudaliar Chattram & Other Charities and Ors.</i> The ruling had held that an arbitration clause in an insufficiently stamped agreement could not be acted upon by the court.`,
    Link: "https://www.livelaw.in/top-stories/arbitration-clause-in-unstamped-agreements-enforceable-supreme-court-refers-nn-global-to-seven-judge-bench-238706?infinitescroll=1",
    image: Image26,
    caseNumber: 26,
  },

  {
    tittle:
      "Karnataka High Court: Penalties Under Stamp Act Not Applicable to Insufficiently Stamped Awards at Execution Stage.",
    description: `In a recent order, the Karnataka High Court has stated that “penalties under the Karnataka Stamp Act, 1957 are not applicable to insufficiently stamped arbitral awards at the juncture of their “enforcement or execution”. This award unambiguously clarifies the legal parameters concerning the imposition of penalties on such awards during the execution proceedings.`,
    Link: "https://www.barandbench.com/news/penalty-insufficiently-stamping-arbitral-award-execution-karnataka-high-court",
    image: Image27,
    caseNumber: 27,
  },

  {
    tittle:
      "Delhi High Court Rules Email Delivery of Arbitral Awards Valid Under Arbitration Act",
    description:
      "In a recent decision, the Delhi High Court stated that the delivery of a scanned, signed copy of an arbitral award via email falls squarely within the ambit of valid delivery as stipulated under Section 31(5) of the Arbitration and Conciliation Act, 1996. This decision clearly delineates the legal standing of electronic delivery methods in arbitration proceedings.",
    Link: "https://www.livelaw.in/pdf_upload/judgement-41-1-489350.pdf",
    image: Image28,
    caseNumber: 28,
  },
];

const AlllCaseChild = ({ caseNumber }) => {
  return (
    <div className="caseModal_wrap">
      <div
        className="alertImg"
      >
        <img
          className="thumbnail"
          src={CaseData[caseNumber - 1].image}
          style={{ width: "100%" }}
          alt="caseAlertImage"
        />
      </div>
      {(() => {
        switch (caseNumber) {
          case 1:
            return <Case1 />;
          case 2:
            return <Case2 />;
          case 3:
            return <Case3 />;
          case 4:
            return <Case4 />;
          case 5:
            return <Case5 />;
          case 6:
            return <Case6 />;
          case 7:
            return <Case7 />;
          case 8:
            return <Case8 />;
          case 9:
            return <Case9 />;
          case 10:
            return <Case10 />;
          case 11:
            return <Case11 />;
          case 12:
            return <Case12 />;
          case 13:
            return <Case13 />;
          case 14:
            return <Case14 />;
          case 15:
            return <Case15 />;
          case 16:
            return <Case16 />;
          case 17:
            return <Case17 />;
          case 18:
            return <Case18 />;
          case 19:
            return <Case19 />;
          case 20:
            return <Case20 />;
          case 21:
            return <Case21 />;
          case 22:
            return <Case22 />;
          case 23:
            return <Case23 />;
          case 24:
            return <Case24 />;
          case 25:
            return <Case25 />;
          case 26:
            return <Case26 />;
          case 27:
            return <Case27 />;
          case 28:
            return <Case28 />;
          default:
            return null;
        }
      })()}
      {CaseData[caseNumber - 1].Link && (
        <a
          className="orange_btn"
          rel="noreferrer"
          target="_blank"
          href={CaseData[caseNumber - 1].Link}
          style={{ minWidth: "max-content", margin: "auto" }}
        >
          Click here for more details
        </a>
      )}
    </div>
  );
};

export default function CaseAlert() {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);
  const [showModel, setShwoModel] = useState(false);
  const [ModalNumber, setModel] = useState(1);

  function showMoreDetail(modelnumber) {
    setModel(modelnumber);
    setShwoModel(true);
  }

  function ExitDetail(value) {
    setShwoModel(value);
  }

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = CaseData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(CaseData.length / recordsPerPage);

  return (
    <div className="caseAlertPage">
      <div className="firstBreak">
        <section className="landing_wrap">
          {window.innerWidth > 600 ? (
            <h1>
              Bringing you the latest updates in the space of arbitration and
              online dispute resolution straight out of court
            </h1>
          ) : (
            <h1 style={{ padding: ".5em" }}>
              Bringing you the latest updates in the space of arbitration and
              online dispute resolution straight out of court
            </h1>
          )}

          <AnimatedBox />
        </section>
      </div>
      <section className="poadcast_main_wrap">
        <DynamicModal
          isOpen={showModel}
          closeMOdel={ExitDetail}
          children={<AlllCaseChild caseNumber={ModalNumber} />}
        />
        {currentRecords.map((poadcast, index) => (
          <div className="resource_wrap" key={index}>
            <div className="course_right_wrap">
              <div className="alertImgContainer">
                <img
                  className="thumbnail"
                  src={CaseAlertImage}
                  style={{ width: "100%" }}
                  alt="caseAlertimage"
                />
                <h5 className="alertTittle">{poadcast.tittle}</h5>
              </div>
            </div>
            <div className="resource_left_wrap">
              <p dangerouslySetInnerHTML={{ __html: poadcast.description }} />
              <div className="resourse_social_media_wrap">
                <div
                  onClick={() => showMoreDetail(poadcast.caseNumber)}
                  className="view_btn"
                >
                  View
                </div>
              </div>
            </div>
          </div>
        ))}
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </section>
    </div>
  );
}
