import React from 'react'

export default function Case11() {
  return (
    <>
    <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>


<i style={{fontSize:  "17px"}}>Case Title: Nalini Gupta Vs. UGRO Capital Limited.</i>        
<i style={{fontSize:  "17px"}}>Court:  Delhi Commercial Court</i>
<i style={{fontSize:  "17px"}}>Date of Judgement:  19.04.2024</i>
    </div>
    
<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>In a significant ruling, the Delhi Commercial Court dismissed objections filed under Section 34 of the Arbitration and Conciliation Act, 1996 (“Act”) challenging an arbitral award on grounds of alleged unilateral appointment of the arbitrator and lack of proper notice to the petitioner.
The petitioner claimed that the arbitrator was appointed unilaterally by the respondent and that the petitioner did not receive any notice seeking its consent for the appointment. However, the court found that the respondent did not appoint the arbitrator but rather approached Presolv360, an ODR institution recognized by the Ministry of Law and Justice, which selected the arbitrator from its panel, and hence the appointment was not unilateral.</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The court in this regard stated that “I am of the considered opinion that it is not the respondent who chose the arbitrator for conducting the arbitral proceedings. <em>The respondent approached an ODR institution Presolv360 and this institution selected one arbitrator from its panel. The arbitrator duly gave declaration u/s 12 of the Act as required and her appointment by the institution is not hit by Section 12 (5) of the Arbitration and Conciliation Act.”</em></p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The petitioner furthermore contended that she did not receive notice of the arbitrator’s appointment or notices under Section 21 of the Act. However, the court observed that notices were sent to the email address and mobile number provided by the objector in the KYC form, establishing valid service. The court highlighted that while postal notices were returned with remarks like “left without instructions,” the petitioner received the copy of the award at the same address, indicating an attempt to avoid the arbitral proceedings.</p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The court emphasized that the petitioner had a valid arbitration agreement, received proper notice of the arbitrator’s appointment, and the award was passed by a neutral person. Consequently, the objections under Section 34 were dismissed.</p>           


<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>This ruling underscores the court’s stance against allowing objections based on unilateral appointment allegations when the arbitrator is appointed by an institutional mechanism. It also highlights the importance of participating in arbitral proceedings and not engaging in attempts to manipulate service records.</p>
</>
  )
}
