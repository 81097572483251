import React from 'react'

export default function Case5() {
  return (
    <>
          <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>

      
    <i style={{fontSize:  "17px"}}>Case Title:  Dhule Municipal Commissioner vs. M/s Borse Borthers Engineers and Contractors Pvt. Ltd</i>        
    <i style={{fontSize:  "17px"}}>Court: High Court of Bombay (Aurangabad Bench)</i>
    <i style={{fontSize:  "17px"}}>Date of Judgement: 15.10.2024</i>
          </div>
          
<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>The Bombay High Court, in a significant ruling, has held that an arbitrator has the authority to change the venue of arbitration proceedings to a more convenient location, even if a specific venue is mentioned in the agreement. The Court emphasized that this change is permissible if conducting proceedings at the agreed venue would be detrimental to the arbitration process.</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The court ruled that Section 20(3) of the Arbitration and Conciliation Act (“Act”) does not completely bar changing the venue without the parties’ consent, even when the venue is agreed upon in the contract and clarified that Section 20(1) of the Act relates to the seat of arbitration, while Section 20 (3) of the Act pertains to the venue.</p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",color: "#383838" }}>The Court noted that the arbitrator had considered various factors in deciding to change the venue, including lack of agreement on the specific venue, difficulties faced by previous arbitrators at the designated seat and previous instances of proceedings being held at a different location. The Court emphasized that the arbitrator can choose a more convenient location for hearings, witness examinations, and other proceedings, as per Section 20(3) of the Act.</p>   

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>This ruling aims to facilitate smoother arbitration proceedings while maintaining the integrity of the arbitration agreement regarding the seat of arbitration. It further provides clarity on the distinction between the seat and venue of arbitration and affirms the arbitrator’s authority to make practical adjustments to ensure efficient proceedings.</p>           
   </>
  )
}
