import React from 'react'

export default function Case6() {
  return (
    <>
          <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>

      
    <i style={{fontSize:  "17px"}}>Case Title:  Ambekeshwar Steel Pvt. Ltd. and Ors. Vs. Yes Bank Ltd. and Ors.</i>        
    <i style={{fontSize:  "17px"}}>Court: Jaipur Commercial Court</i>
    <i style={{fontSize:  "17px"}}>Date of Judgement: 25.07.2024</i>
          </div>
          
<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500", color: "#383838" }}>In a significant ruling, the Jaipur Commercial Court dismissed objections filed under Section 34 of the Arbitration and Conciliation Act, 1996 (“Act”) challenging an arbitral award in an arbitration administered by Presolv360 ODR Institution. The objections raised against the arbitral award were based on four grounds:</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}><b style={{fontSize:  "17px"}}><em>1.Unilateral Appointment of Arbitrator</em></b><br></br>
Contention: The Petitioners contended that the arbitrator was unilaterally appointed by the bank, without their consent.</p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>Court’s Ruling: The Court observed that the bank approached an independent ODR institution, Presolv360 for the appointment of the arbitrator. Following this, Presolv360 sent a notification of registration of arbitration intimating the parties about administering of the arbitration through Presolv360, which is an independent institution with no conflict of interest. The notices were not only received by email and WhatsApp by the parties but the service report showed that such emails and messages were also seen by them. The Petitioners did not raise any objections at the time. Additionally, the parties were given the option to select another arbitrator from the panel maintained by Presolv360 by mutual consent, but such option was not availed. The Court found that the appointment process was in accordance with law, and the objection regarding unilateral appointment was untenable.</p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}><b style={{fontSize:  "17px"}}><em>2.Unilateral Appointment of Arbitrator</em></b><br></br>
Contention: The Petitioners argued that the arbitrator’s appointment by Presolv360 was not valid as it was not clearly mentioned under which law Presolv360 had the authority to appoint an arbitrator.</p> 

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>Court’s Ruling: The Court held that Presolv360 is recognized ADR institution, providing administrative and technical support in conduct of arbitration proceedings virtually through its platform. The Court found the appointment to be lawful and within the framework of the Act. The Court also referred to Sections 2(6) and 6 of the Act which enable an institution to provide administrative assistance for the conduct of arbitration proceedings.</p>  

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}><b style={{fontSize:  "17px"}}><em>3.Unilateral Appointment of Arbitrator</em></b><br></br>
        Contention: The Petitioners claimed they did not receive proper notice of the arbitral proceedings and the award, arguing that service through WhatsApp was not proper service.</p>   

      <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>Court’s Ruling: The Court observed that all notices were duly served to the Petitioners through both email and WhatsApp. It was noted that the Petitioners had received and read these notices, as evidenced by the service report provided by Presolv360. The Court noted that the arbitrator had issued notices and directions to both parties and fixed multiple dates for the Petitioners to appear and respond, which they failed to do. The signed copy of the award passed was also sent to the parties through both email and WhatsApp, and was received and seen by them. The Court found no evidence that service through email and WhatsApp was technically illegal or unlawful.</p>   

      <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}><b style={{fontSize:  "17px"}}><em>4.Interest Rate and Stamp Duty</em></b><br></br>
      Contention: The Petitioners argued that the interest rate charged was not agreed upon and that the loan agreement was executed on insufficient stamp duty.</p>   

      <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>Court’s Ruling: The Court held that the interest rate was specified in the loan documents provided at the time of the agreement, and the Petitioners had made payments of instalments accordingly without raising objections. Additionally, the Court found no merit in the claim regarding insufficient stamp duty on the loan agreement. The arbitrator had awarded future interest at the rate of 12% per annum, aligning with the mandate of Section 31(7) of the Act.</p>  

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}><b style={{fontSize:  "17px"}}><em>Conclusion</em></b><br></br>The Court validated administration of the arbitration proceedings by Presolv360 ODR Institution and held that the appointment of the arbitrator from the institution’s panel was in accordance with law. The Court further validated service of notices through email and WhatsApp through the institution’s digital infrastructure. Finally, the Court upheld the interest awarded and found the entire proceedings to be in accordance with the Act. Consequently, the Court dismissed the objections of the Petitioners and confirmed the arbitral award.</p>           
   </>
  )
}
