import React from 'react'

export default function Case12() {
  return (
    <>
    <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>


<i style={{fontSize:  "17px"}}>Case Title: Arjun Mall Retail Holdings (P) Ltd. v. Gunocen Inc.</i>        
<i style={{fontSize:  "17px"}}>Court: Delhi High Court</i>
<i style={{fontSize:  "17px"}}>Date of Judgement:  23.01.2024</i>
    </div>
    
<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>The Hon’ble Delhi High Court highlighted the importance of following proper procedures when objecting to the appointment of an arbitrator under an arbitration agreement. The court upheld the rejection of a challenge under Section 34 of the Arbitration and Conciliation Act, 1996 (“Act”) against an arbitral award, where the arbitrator was appointed unilaterally as per the arbitration clause.</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>While the court did not expressly rule on the validity of such unilateral appointments, it emphasized that merely raising informal objections, such as through a letter to the arbitrator citing lack of consent, is insufficient. The appellant in this case had failed to legally challenge the appointment or the validity of the arbitration clause itself through the appropriate mechanism under Section 11(6) of the Act during the time gap before commencement of the arbitration.</p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>The Hon’ble Court termed the conduct of the aggrieved party as that of a “mute spectator”. It was noted that by not participating in the subsequent arbitration proceedings despite having knowledge of the proceedings, after the initial objections raised by a letter were overruled, the aggrieved party must be deemed to have waived their right to later challenge the appointment.</p>            

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>The Hon’ble Court emphasized that under Section 34, the scope to interfere with an arbitral award is limited only to grounds raised during the arbitral proceedings.</p>
</>
  )
}
