import React from 'react'

export default function Case26() {
  return (
    <>
      
    <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>
      <i style={{fontSize:  "17px"}}>Case Title: Bhaskar Raju and Brothers and Anr V. s Dharmaratnakara Rai Bahadur Arcot Narainswamy Mudaliar Chattram & Other Charities and Ors</i>
      <i style={{fontSize:  "17px"}}>Court: Supreme Court of India</i>
      <i style={{fontSize:  "17px"}}>Date of Order: 26.09.2023</i>
    </div>

    <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",color: "#383838"}}>The Supreme Court of India has referred the question of whether unstamped or insufficiently stamped arbitration agreements are enforceable to a seven-judge bench. This decision was made by a five-judge bench led by the Chief Justice of India, DY Chandrachud while hearing a curative petition in the case of <em>Bhaskar Raju and Brothers and Anr V. s Dharmaratnakara Rai Bahadur Arcot Narainswamy Mudaliar Chattram & Other Charities and Ors.</em> The ruling had held that an arbitration clause in an insufficiently stamped agreement could not be acted upon by the court.
    </p>

    <p style={{ paddingBottom: "2em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",color: "#383838"}}>
    The reference was made in the hearing when the recently delivered judgment of the Hon’ble Supreme Court in the case of <em>M/s. N.N. Global Mercantile Pvt. Ltd. v. M/s. Indo Unique Flame
    Ltd. And Ors</em> came for consideration. The Hon’ble Supreme Court bench emphasized that the issue is of significant importance and has created “limitless uncertainty” in the field of Arbitration law, and hence decided to refer the issue to a larger bench.</p>
  
    </>
  )
}