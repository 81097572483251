import React from 'react'

export default function Case23() {
  return (
    <>
          
      <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>
    <i style={{fontSize:  "17px"}}>Case Title: M/s. Arg Outlier Media Private Limited v/s HT Media Limited</i>
    <i style={{fontSize:  "17px"}}>Court: Delhi High Court</i>
    <i style={{fontSize:  "17px"}}>Date of Judgment: 04.07.2023</i>
</div>

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",color: "#383838" }}>Recently, the Hon’ble Delhi High Court held that an arbitration award cannot be set aside solely because the arbitration agreement was insufficiently stamped as per the requirements of the Stamp Act. The insufficient stamping of the agreement is only a violation of the Stamp Act requirements and does not by itself provide grounds for setting aside the resultant award.
</p>

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838" }}>The Hon’ble court was specifically dealing with an issue related to the Maharashtra Stamp Act, 1958. The Court clarified that under a Section 34 jurisdiction of the Arbitration and Conciliation Act, 1996, the Court was not a Court of Appeal against the findings of the Arbitral Tribunal.Therefore, while an arbitration agreement is required to be properly stamped as per stamp duty in order to be admissible as evidence, however, if the arbitrator has already admitted the insufficiently stamped agreement and passed an award based on it then the award cannot be challenged later solely on the ground that the agreement was not adequately stamped.
</p>
   </>
  )
}