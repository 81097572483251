import React from 'react'

export default function Case27() {
  return (
    <>
 
    <div style={{ fontFamily: "Montserrat",color: "#383838", fontWeight: "400" }}>
           <i style={{fontSize:  "17px"}}>Case Title: Shakeel Pasha and Ors v. City Max Hotels</i>
           <i style={{fontSize:  "17px"}}>Court: Karnataka High Court</i>
           <i style={{fontSize:  "17px"}}>Date of Judgement: 28.07.2023</i>
         </div>
   
         <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500", color: "#383838"}}>
           In a recent order, the Karnataka High Court has stated that 
           <b style={{fontSize:  "17px", color: "#383838"}}> "penalties under the Karnataka Stamp Act, 1957 are not applicable to insufficiently stamped arbitral awards at the juncture of their “enforcement or execution”.</b> 
           This award unambiguously clarifies the legal parameters concerning the imposition of penalties on such awards during the execution proceedings.
         </p>
   
         <p style={{ paddingBottom: "2em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500", color: "#383838"}}>
           The court specifically stated that 
           <em> “The concept of imposing penalty on an insufficiently stamped instrument cannot be applied to an arbitral award in an execution proceedings.”</em>
         </p>
   
   </> 
  )
}