import axios from "axios";

const Bussiness = (data) => {
  var formdata = new FormData();
  formdata.append("name", data.name);
  formdata.append("orgname", data.orgname);
  formdata.append("email", data.email);
  formdata.append("phone", data.phone);
  return axios.post(
    "https://script.google.com/macros/s/AKfycbwWLGoHfx5pW_BMuLzKq0-8vfYUz1UAHEfJwPkcvvELh-gGFYx7N9KZc68lQQX7JY9gcQ/exec",
    formdata
  );
};

const NewsLetter = (email) => {
  var formdata = new FormData();
  formdata.append("email", email);
  return axios.post(
    "https://script.google.com/macros/s/AKfycbzR9q8Zn2NJur3rI7b9wpzl3RV0kxt34V49zb1M-USiRmir4SqDOBASpAgVvmuZ566a/exec",
    formdata
  );
};

export { Bussiness, NewsLetter };
