import React from 'react'

export default function Case13() {
  return (
    <>
          <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>

      
    <i style={{fontSize:  "17px"}}>Case Title: M/S Lease Plan India Private Limited vs M/S Rudraksh Pharma Distributor & Ors.</i>        
    <i style={{fontSize:  "17px"}}>Court: Delhi High Court</i>
    <i style={{fontSize:  "17px"}}>Date of Judgement:  10.04.2024</i>
          </div>
          
<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>The Hon’ble Delhi High Court allowed a petition filed under Section 11 of the Arbitration and Conciliation Act, 1996 (“A&C Act”), referring the dispute to the Delhi International Arbitration Centre (“DIAC”) to appoint the arbitrator and adjudicate disputes between the parties. This decision came after the respondents failed to appear despite being served through email and WhatsApp as per the contact details mentioned in the underlying Lease Agreement.</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>The petitioner had invoked arbitration by a notice dated 31.12.2022, proposing three names as arbitrators. However, the respondents did not respond. The Court noted that the respondents were duly served on 20.02.2024 via email and WhatsApp on the email and phone numbers provided in the agreement, as evidenced by an affidavit filed by the petitioner’s counsel. Despite attempts at postal service being unsuccessful, the Court held the email and WhatsApp service sufficient to proceed.</p>            

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>Satisfied with the existence of an arbitration agreement, the Court referred the disputes to DIAC, directing it to nominate an arbitrator from its panel.</p>
   </>
  )
}