import React from 'react'
import Slider from 'react-slick'
  import Career1 from  '../assets/Images/Carrer_img-1.png'
  import Career2 from  '../assets/Images/Diwali Party.png'
  import Career3 from  '../assets/Images/Old office.png'
  // import Career4 from  '../assets/Images/photo_2024-01-30_15-59-12 1.png'
  import Career5 from  '../assets/Images/career_2.png'

import AnimatedBox from '../components/AnimatedBox'
import { Helmet } from 'react-helmet'
export default function Career() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    dotsClass:'sliderDots',
    customPaging: i => (
      <div
      >
        {i + 1}
      </div>
    )}
  return (
    <div className='careerPage'>
   <Helmet>
   <title>Presolv360 | Careers | Online Dispute Resolution | Legaltech</title>
<meta name="description" content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally." />
<meta name="keywords" content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India" />
<link rel="canonical" href="https://presolv360.com/career" />
<meta property="og:keywords" content="ODR, ADR, Online Dispute Resolution, Alternative Dispute Resolution, Out of Court Settlement, Arbitration, Mediation, Conciliation, Online Arbitration, Online Negotiation, Money Recovery, Legaltech, ODR India" />
<meta property="og:description" content="Online Dispute Resolution platform to conduct arbitration, mediation, conciliation digitally." />
   </Helmet>
   
    <section className='landing_wrap firstBreak'>
       {
        window.innerWidth > 600 ?   <h1 data-aos="fade-right" data-aos-duration="1500">
        We’re looking for passionate people who <br></br> want to  revolutionize the future of <br></br>  dispute resolution!
        </h1> :   <h1 style={{padding:"0 .5em",fontSize:"32px"}} data-aos="fade-right" data-aos-duration="1500">
        We’re looking for passionate people who want to build on what we’ve created, and revolutionize the future of dispute resolution!
        </h1>
       }
      
         <h3 data-aos="fade-left" data-aos-duration="1500">Presolv<span style={{color:"var(--color_2)",fontWeight:"700"}}>360</span> is yours to build</h3>
         <AnimatedBox/>
    </section>   
    <div className='section_heading'>
          <h2 className='blue_heading'data-aos="fade-right" data-aos-duration="1500">Open Positions</h2>
        </div>
        <div>
          <section className='job_card_wrap'>
            {/* <a href='mailto:people@presolv360.com' className='career_card'>
               <h3>HR Manager <br></br></h3>
              
               <p>Experience: 5+ years <br></br>
Location: Mumbai</p>
<svg className='arrowsvg_career' width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.87547 20C4.87547 19.5027 5.07302 19.0258 5.42465 18.6742C5.77628 18.3226 6.25319 18.125 6.75047 18.125L29.7192 18.125L21.6692 10.0766C21.317 9.72434 21.1191 9.2466 21.1191 8.74846C21.1191 8.25031 21.317 7.77257 21.6692 7.42033C22.0215 7.06809 22.4992 6.8702 22.9974 6.8702C23.4955 6.8702 23.9732 7.06809 24.3255 7.42033L35.5755 18.6703C35.7503 18.8445 35.889 19.0515 35.9836 19.2794C36.0782 19.5073 36.127 19.7517 36.127 19.9985C36.127 20.2452 36.0782 20.4896 35.9836 20.7175C35.889 20.9454 35.7503 21.1524 35.5755 21.3266L24.3255 32.5766C24.1511 32.751 23.944 32.8893 23.7161 32.9837C23.4882 33.0781 23.244 33.1267 22.9974 33.1267C22.7507 33.1267 22.5065 33.0781 22.2786 32.9837C22.0507 32.8893 21.8436 32.751 21.6692 32.5766C21.4948 32.4022 21.3565 32.1951 21.2621 31.9672C21.1677 31.7394 21.1191 31.4951 21.1191 31.2485C21.1191 31.0018 21.1677 30.7576 21.2621 30.5297C21.3565 30.3018 21.4948 30.0947 21.6692 29.9203L29.7192 21.875L6.75047 21.875C6.25319 21.875 5.77628 21.6775 5.42465 21.3258C5.07302 20.9742 4.87547 20.4973 4.87547 20Z" fill="#454545"/>
</svg> 
            </a>
            <a href='mailto:people@presolv360.com' className='career_card'>
               <h3>PR & Comms Executive</h3>
               <p>Experience: 2 years <br></br>
Location: Mumbai </p>
<svg className='arrowsvg_career' width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.87547 20C4.87547 19.5027 5.07302 19.0258 5.42465 18.6742C5.77628 18.3226 6.25319 18.125 6.75047 18.125L29.7192 18.125L21.6692 10.0766C21.317 9.72434 21.1191 9.2466 21.1191 8.74846C21.1191 8.25031 21.317 7.77257 21.6692 7.42033C22.0215 7.06809 22.4992 6.8702 22.9974 6.8702C23.4955 6.8702 23.9732 7.06809 24.3255 7.42033L35.5755 18.6703C35.7503 18.8445 35.889 19.0515 35.9836 19.2794C36.0782 19.5073 36.127 19.7517 36.127 19.9985C36.127 20.2452 36.0782 20.4896 35.9836 20.7175C35.889 20.9454 35.7503 21.1524 35.5755 21.3266L24.3255 32.5766C24.1511 32.751 23.944 32.8893 23.7161 32.9837C23.4882 33.0781 23.244 33.1267 22.9974 33.1267C22.7507 33.1267 22.5065 33.0781 22.2786 32.9837C22.0507 32.8893 21.8436 32.751 21.6692 32.5766C21.4948 32.4022 21.3565 32.1951 21.2621 31.9672C21.1677 31.7394 21.1191 31.4951 21.1191 31.2485C21.1191 31.0018 21.1677 30.7576 21.2621 30.5297C21.3565 30.3018 21.4948 30.0947 21.6692 29.9203L29.7192 21.875L6.75047 21.875C6.25319 21.875 5.77628 21.6775 5.42465 21.3258C5.07302 20.9742 4.87547 20.4973 4.87547 20Z" fill="#454545"/>
</svg>
            </a>
            <a href='mailto:people@presolv360.com' className='career_card'>
               <h3>In-house Counsel</h3>
               <p>Experience: 5-7 years<br></br>
Location: Mumbai </p>
<svg className='arrowsvg_career' width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.87547 20C4.87547 19.5027 5.07302 19.0258 5.42465 18.6742C5.77628 18.3226 6.25319 18.125 6.75047 18.125L29.7192 18.125L21.6692 10.0766C21.317 9.72434 21.1191 9.2466 21.1191 8.74846C21.1191 8.25031 21.317 7.77257 21.6692 7.42033C22.0215 7.06809 22.4992 6.8702 22.9974 6.8702C23.4955 6.8702 23.9732 7.06809 24.3255 7.42033L35.5755 18.6703C35.7503 18.8445 35.889 19.0515 35.9836 19.2794C36.0782 19.5073 36.127 19.7517 36.127 19.9985C36.127 20.2452 36.0782 20.4896 35.9836 20.7175C35.889 20.9454 35.7503 21.1524 35.5755 21.3266L24.3255 32.5766C24.1511 32.751 23.944 32.8893 23.7161 32.9837C23.4882 33.0781 23.244 33.1267 22.9974 33.1267C22.7507 33.1267 22.5065 33.0781 22.2786 32.9837C22.0507 32.8893 21.8436 32.751 21.6692 32.5766C21.4948 32.4022 21.3565 32.1951 21.2621 31.9672C21.1677 31.7394 21.1191 31.4951 21.1191 31.2485C21.1191 31.0018 21.1677 30.7576 21.2621 30.5297C21.3565 30.3018 21.4948 30.0947 21.6692 29.9203L29.7192 21.875L6.75047 21.875C6.25319 21.875 5.77628 21.6775 5.42465 21.3258C5.07302 20.9742 4.87547 20.4973 4.87547 20Z" fill="#454545"/>
</svg>
            </a>
            <a href='mailto:people@presolv360.com' className='career_card'>
               <h3>Legal Associate</h3>
               <p>Experience: 2 years <br></br>
Location: Mumbai </p>
<svg className='arrowsvg_career' width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.87547 20C4.87547 19.5027 5.07302 19.0258 5.42465 18.6742C5.77628 18.3226 6.25319 18.125 6.75047 18.125L29.7192 18.125L21.6692 10.0766C21.317 9.72434 21.1191 9.2466 21.1191 8.74846C21.1191 8.25031 21.317 7.77257 21.6692 7.42033C22.0215 7.06809 22.4992 6.8702 22.9974 6.8702C23.4955 6.8702 23.9732 7.06809 24.3255 7.42033L35.5755 18.6703C35.7503 18.8445 35.889 19.0515 35.9836 19.2794C36.0782 19.5073 36.127 19.7517 36.127 19.9985C36.127 20.2452 36.0782 20.4896 35.9836 20.7175C35.889 20.9454 35.7503 21.1524 35.5755 21.3266L24.3255 32.5766C24.1511 32.751 23.944 32.8893 23.7161 32.9837C23.4882 33.0781 23.244 33.1267 22.9974 33.1267C22.7507 33.1267 22.5065 33.0781 22.2786 32.9837C22.0507 32.8893 21.8436 32.751 21.6692 32.5766C21.4948 32.4022 21.3565 32.1951 21.2621 31.9672C21.1677 31.7394 21.1191 31.4951 21.1191 31.2485C21.1191 31.0018 21.1677 30.7576 21.2621 30.5297C21.3565 30.3018 21.4948 30.0947 21.6692 29.9203L29.7192 21.875L6.75047 21.875C6.25319 21.875 5.77628 21.6775 5.42465 21.3258C5.07302 20.9742 4.87547 20.4973 4.87547 20Z" fill="#454545"/>
</svg>
            </a> */}
            <a href='mailto:people@presolv360.com' className='career_card'>
               <h3>Assistant Manager (Operations)</h3>
               <p>Experience: 5+ years<br></br>
Location: Mumbai </p>
<svg className='arrowsvg_career' width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.87547 20C4.87547 19.5027 5.07302 19.0258 5.42465 18.6742C5.77628 18.3226 6.25319 18.125 6.75047 18.125L29.7192 18.125L21.6692 10.0766C21.317 9.72434 21.1191 9.2466 21.1191 8.74846C21.1191 8.25031 21.317 7.77257 21.6692 7.42033C22.0215 7.06809 22.4992 6.8702 22.9974 6.8702C23.4955 6.8702 23.9732 7.06809 24.3255 7.42033L35.5755 18.6703C35.7503 18.8445 35.889 19.0515 35.9836 19.2794C36.0782 19.5073 36.127 19.7517 36.127 19.9985C36.127 20.2452 36.0782 20.4896 35.9836 20.7175C35.889 20.9454 35.7503 21.1524 35.5755 21.3266L24.3255 32.5766C24.1511 32.751 23.944 32.8893 23.7161 32.9837C23.4882 33.0781 23.244 33.1267 22.9974 33.1267C22.7507 33.1267 22.5065 33.0781 22.2786 32.9837C22.0507 32.8893 21.8436 32.751 21.6692 32.5766C21.4948 32.4022 21.3565 32.1951 21.2621 31.9672C21.1677 31.7394 21.1191 31.4951 21.1191 31.2485C21.1191 31.0018 21.1677 30.7576 21.2621 30.5297C21.3565 30.3018 21.4948 30.0947 21.6692 29.9203L29.7192 21.875L6.75047 21.875C6.25319 21.875 5.77628 21.6775 5.42465 21.3258C5.07302 20.9742 4.87547 20.4973 4.87547 20Z" fill="#454545"/>
</svg>
            </a>
            <a href='mailto:people@presolv360.com' className='career_card'>
               <h3>Senior Executive (Operations)</h3>
               <p>Experience: 3-5 years <br></br>
Location: Mumbai </p>
<svg className='arrowsvg_career' width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.87547 20C4.87547 19.5027 5.07302 19.0258 5.42465 18.6742C5.77628 18.3226 6.25319 18.125 6.75047 18.125L29.7192 18.125L21.6692 10.0766C21.317 9.72434 21.1191 9.2466 21.1191 8.74846C21.1191 8.25031 21.317 7.77257 21.6692 7.42033C22.0215 7.06809 22.4992 6.8702 22.9974 6.8702C23.4955 6.8702 23.9732 7.06809 24.3255 7.42033L35.5755 18.6703C35.7503 18.8445 35.889 19.0515 35.9836 19.2794C36.0782 19.5073 36.127 19.7517 36.127 19.9985C36.127 20.2452 36.0782 20.4896 35.9836 20.7175C35.889 20.9454 35.7503 21.1524 35.5755 21.3266L24.3255 32.5766C24.1511 32.751 23.944 32.8893 23.7161 32.9837C23.4882 33.0781 23.244 33.1267 22.9974 33.1267C22.7507 33.1267 22.5065 33.0781 22.2786 32.9837C22.0507 32.8893 21.8436 32.751 21.6692 32.5766C21.4948 32.4022 21.3565 32.1951 21.2621 31.9672C21.1677 31.7394 21.1191 31.4951 21.1191 31.2485C21.1191 31.0018 21.1677 30.7576 21.2621 30.5297C21.3565 30.3018 21.4948 30.0947 21.6692 29.9203L29.7192 21.875L6.75047 21.875C6.25319 21.875 5.77628 21.6775 5.42465 21.3258C5.07302 20.9742 4.87547 20.4973 4.87547 20Z" fill="#454545"/>
</svg>
            </a>
            <a href='mailto:people@presolv360.com' className='career_card'>
               <h3>Executive (Operations)</h3>
               <p>Experience: 1+ year <br></br>
Location: Mumbai </p>
<svg className='arrowsvg_career' width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.87547 20C4.87547 19.5027 5.07302 19.0258 5.42465 18.6742C5.77628 18.3226 6.25319 18.125 6.75047 18.125L29.7192 18.125L21.6692 10.0766C21.317 9.72434 21.1191 9.2466 21.1191 8.74846C21.1191 8.25031 21.317 7.77257 21.6692 7.42033C22.0215 7.06809 22.4992 6.8702 22.9974 6.8702C23.4955 6.8702 23.9732 7.06809 24.3255 7.42033L35.5755 18.6703C35.7503 18.8445 35.889 19.0515 35.9836 19.2794C36.0782 19.5073 36.127 19.7517 36.127 19.9985C36.127 20.2452 36.0782 20.4896 35.9836 20.7175C35.889 20.9454 35.7503 21.1524 35.5755 21.3266L24.3255 32.5766C24.1511 32.751 23.944 32.8893 23.7161 32.9837C23.4882 33.0781 23.244 33.1267 22.9974 33.1267C22.7507 33.1267 22.5065 33.0781 22.2786 32.9837C22.0507 32.8893 21.8436 32.751 21.6692 32.5766C21.4948 32.4022 21.3565 32.1951 21.2621 31.9672C21.1677 31.7394 21.1191 31.4951 21.1191 31.2485C21.1191 31.0018 21.1677 30.7576 21.2621 30.5297C21.3565 30.3018 21.4948 30.0947 21.6692 29.9203L29.7192 21.875L6.75047 21.875C6.25319 21.875 5.77628 21.6775 5.42465 21.3258C5.07302 20.9742 4.87547 20.4973 4.87547 20Z" fill="#454545"/>
</svg>
            </a>
            {/* <a href='mailto:people@presolv360.com' className='career_card'>
               <h3>Manager (Operations)</h3>
               <p>Experience: 7+ year <br></br>
Location: Mumbai </p>
<svg className='arrowsvg_career' width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.87547 20C4.87547 19.5027 5.07302 19.0258 5.42465 18.6742C5.77628 18.3226 6.25319 18.125 6.75047 18.125L29.7192 18.125L21.6692 10.0766C21.317 9.72434 21.1191 9.2466 21.1191 8.74846C21.1191 8.25031 21.317 7.77257 21.6692 7.42033C22.0215 7.06809 22.4992 6.8702 22.9974 6.8702C23.4955 6.8702 23.9732 7.06809 24.3255 7.42033L35.5755 18.6703C35.7503 18.8445 35.889 19.0515 35.9836 19.2794C36.0782 19.5073 36.127 19.7517 36.127 19.9985C36.127 20.2452 36.0782 20.4896 35.9836 20.7175C35.889 20.9454 35.7503 21.1524 35.5755 21.3266L24.3255 32.5766C24.1511 32.751 23.944 32.8893 23.7161 32.9837C23.4882 33.0781 23.244 33.1267 22.9974 33.1267C22.7507 33.1267 22.5065 33.0781 22.2786 32.9837C22.0507 32.8893 21.8436 32.751 21.6692 32.5766C21.4948 32.4022 21.3565 32.1951 21.2621 31.9672C21.1677 31.7394 21.1191 31.4951 21.1191 31.2485C21.1191 31.0018 21.1677 30.7576 21.2621 30.5297C21.3565 30.3018 21.4948 30.0947 21.6692 29.9203L29.7192 21.875L6.75047 21.875C6.25319 21.875 5.77628 21.6775 5.42465 21.3258C5.07302 20.9742 4.87547 20.4973 4.87547 20Z" fill="#454545"/>
</svg>
            </a> */}
          </section>

        </div>
        <div className='section_heading' style={{padding:"5em"}}>
          <h2 className='blue_heading'data-aos="fade-up" data-aos-duration="1500">LIFE AT PRESOLV360</h2>
        </div>
        <Slider {...settings} >
            <div>
              <div className='life_img_wrap'>
                <img src={Career1} alt='image1'/>
              </div>
            </div>
            <div>
              <div className='life_img_wrap'>
                <img src={Career2} alt='image1'/>
              </div>
            </div>
            <div>
              <div className='life_img_wrap'>
                <img src={Career3} alt='image1'/>
              </div>
            </div>
            <div>
              <div className='life_img_wrap'>
                <img src={Career5} alt='image1'/>
              </div>
            </div>
        </Slider>
        {/* <div  style={{width:"100%", display:"grid",placeItems:"center",paddingBottom:"4em"}}>
          <img src={Grate} alt='Great place to work' style={{width:"90%"}} />
        </div> */}
    </div>
  )
}
