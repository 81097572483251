import React from 'react'

export default function Case4() {
  return (
    <>
      <p style={{paddingTop: "1em",fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",color: "#383838",}}>The Draft Arbitration and Conciliation (Amendment) Bill, 2024 (“Bill”)
        proposes several changes to the existing Arbitration and Conciliation Act, 1996 (“Act”). Among these amendments, there are provisions that are particularly relevant to Online Dispute Resolution (“ODR”). These changes aim to modernize the arbitration process in India and make it more amenable to digital platforms and remote proceedings.</p>

      <p style={{paddingTop: "1em",fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838",}}>
        <b style={{fontSize:  "17px"}}>1. Key amendments pertaining to ODR :</b></p>

      <table style={{width: "100%",borderCollapse: "collapse",fontFamily: "Montserrat",}}
      >
        <thead>
          <tr>
            <th style={{border: "1px solid black",padding: "8px",textAlign: "left",}}>Section</th>
            <th style={{border: "1px solid black",padding: "8px", textAlign: "left", }}>Proposed Amendment</th>
            <th style={{border: "1px solid black",padding: "8px",textAlign: "left",}}>Relevance to ODR</th>
          </tr>
        </thead>
        <tbody>
          {[
            {
              section: "2(1)(a)",
              amendment:
                "Definition of “arbitration” expanded to include “arbitration conducted, wholly or partly, by use of audio-video electronic means”",
              relevance:
                "Explicitly recognizes and allows for online arbitration",
            },
            {
              section: "2(1)(aa)",
              amendment: 'New definition of “audio-video electronic means”',
              relevance:
                "Provides a legal basis for conducting arbitration proceedings through digital platforms",
            },
            {
              section: "19(5)",
              amendment:
                "Explicit provision allowing for proceedings to be conducted through audio-video electronic means in the context of determining the rules of procedure",
              relevance: "Enables end-to-end online arbitration proceedings",
            },
            {
              section: "7(4) and 31(5)",
              amendment:
                "Allows for digitally signed agreements and arbitral awards",
              relevance: "Facilitates paperless arbitration processes",
            },
            {
              section: "43D(2)(h)",
              amendment:
                "Council to specify manner of conducting proceedings through audio-video electronic means",
              relevance: "Ensures standardization of online arbitration processes",
            },
            {
              section: "43M",
              amendment:
                "Establishment of a digital depository for arbitration cases",
              relevance:
                "Enables better tracking and management of online arbitrations",
            },
            {
              section: "43Q(2)(d)",
              amendment:
                "Council to make regulations for conduct of proceedings through audio-video electronic means",
              relevance:
                "Provides for detailed guidelines on conducting online arbitrations",
            },
          ].map((row, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid black", padding: "8px" }}>{row.section}</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>{row.amendment}</td>
              <td style={{ border: "1px solid black", padding: "8px" }}>{row.relevance}</td>
            </tr>
          ))}
        </tbody>
      </table>

    <p style={{paddingTop: "1em",fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838",}}><b style={{fontSize:  "17px"}}>2.Analysis of key provisions pertaining to ODR :</b></p>

      <p style={{paddingTop: "1em",fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838",}}><b style={{fontSize:  "17px"}}>Recognition of Online Arbitration - Section 2(1)(a) and 2(1)(aa) :</b><br></br>The explicit inclusion of audio-video electronic means in the definition of arbitration is a significant step towards bolstering ODR. This broad definition with wording such as “any communication device” acknowledges the technological advancements in dispute resolution and paves the way for more innovative ODR solutions.</p>
          
    <p style={{paddingTop: "1em",fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838",}}><b style={{fontSize:  "17px"}}>Flexibility in Arbitration Proceedings - Section 19(5) :
          </b><br></br>By allowing proceedings to be conducted through electronic means, the amendment proposes greater flexibility to parties and arbitrators. This is an acknowledgment of more efficient and cost-effective arbitrations, especially in cases involving parties from different geographical locations.</p>
          
          <p style={{paddingTop: "1em",fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838",}}><b style={{fontSize:  "17px"}}>Digital Signatures and Awards - Sections 7(4)(a) and 31(5) :
          </b><br></br>The recognition of digital signatures for both arbitration agreements and awards is crucial for a complete end-to-end online arbitration process. This change also aligns the arbitration framework with the broader push towards digital dispute resolution and paperless transactions.</p>
          
          <p style={{paddingTop: "1em",fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838",}}><b style={{fontSize:  "17px"}}>Standardization and Regulation - Sections 43D(2)(h) and 43Q(2)(d) :
          </b><br></br>The empowerment of the Arbitration Council to specify and regulate the conduct of online proceedings is vital for ensuring the quality and reliability of ODR services. This can help in building trust in online arbitration processes and promote their wider adoption.</p>
          

          <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838", }}>The establishment of a digital depository for arbitration cases is a significant step towards modernizing case management in arbitrations. This can improve transparency, facilitate easier access to case information, and enable better analysis of arbitration trends.</p>
          
          <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838", }}>These proposed amendments demonstrate a clear intent to make ODR mainstream within the Indian arbitration framework. By explicitly recognizing and providing for audio-video proceedings, digital signatures, and online case management, the Bill aims to modernize arbitration practices and make them more accessible and efficient.</p>
          
          <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838", }}><b style={{fontSize:  "17px"}}>3.Other key provisions in the Bill</b></p>
          
          <p style={{paddingTop: "1em",fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838",}}><b style={{fontSize:  "17px"}}>Redefined Framework for Arbitral Institutions - Section 2(ca) :
          </b><br></br>The Bill introduces a significant change in how arbitral institutions are defined. The new definition characterizes an arbitral institution as “<em>a body or</em> <b style={{fontSize:  "17px"}}>organization</b> <em>that provides for conduct of arbitration proceedings under its aegis, by an arbitral tribunal as per its own rules of procedure or as otherwise agreed by the parties.</em>” It is our understanding that this broader definition creates two categories of arbitral institutions viz. (i) arbitral institutions that can conduct arbitrations independently based on their rules of procedure; and (ii) institutions that are specifically recognized by the Arbitration Council of India under Section 43K for the purpose of appointing arbitrators through the institution rather than the jurisdictional Court.</p>

          <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838", }}><b style={{fontSize:  "17px"}}>Emergency Arbitrator - Section 9A :</b><br></br>The Bill introduces emergency arbitration for the first time in Indian law. Emergency arbitrators can grant interim measures before the constitution of the main tribunal, with their orders having the same enforceability as regular tribunal orders.</p>
          
          <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838", }}>This addition significantly strengthens the interim relief mechanism in institutional arbitration and aligns Indian arbitration law with international best practices.</p>

          <p style={{paddingTop: "1em",fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838",}}><b style={{fontSize:  "17px"}}>Time-bound Adjudication of Jurisdictional Challenges - Section 16 :
          </b><br></br>The Bill mandates a 30-day timeline for deciding jurisdictional challenges as preliminary issues.</p>

          <p style={{paddingTop: "1em",fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838",}}><b style={{fontSize:  "17px"}}>Clarification of Seat/Venue Concepts - Section 20 :
          </b><br></br>The Bill proposes two alternative approaches to address the often-complicated issue of arbitral seat and venue. Option I follows a traditional approach, replacing “place” with “seat” throughout the Act and maintaining a clear distinction between the juridical seat and venue (physical/virtual location of hearings). This option preserves parties’ freedom to choose while providing default determination by the tribunal.</p>
      
          <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838", }}>Option II introduces a more civil procedure-like approach for domestic arbitrations, where the seat would be determined by the place where the contract/agreement is executed or the place where the cause of action arises.</p>

          <p style={{paddingTop: "1em",fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838",}}><b style={{fontSize:  "17px"}}>Form and Content of Awards – Section 31 :
          </b><br></br>Section 31(1) now expressly requires awards to be “duly stamped”. Section 31(2A) introduces additional requirements to be captured in awards. Tribunals must now verify several aspects, including party capacity, agreement validity, due process compliance, proper tribunal composition, procedural adherence, arbitrability, and scope compliance. This ensures awards are comprehensive and enforceable, reducing grounds for future challenges.</p>

          <p style={{paddingTop: "1em",fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838",}}><b style={{fontSize:  "17px"}}>Records Management Protocol – Section 32(4) :
          </b><br></br>In institutional arbitration, the arbitral record will be maintained by the institution, while in ad hoc arbitrations, the record will be returned to the parties.</p>

          <p style={{paddingTop: "1em",fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838",}}><b style={{fontSize:  "17px"}}>Appellate Arbitration Framework – Sections 34, 34A :
          </b><br></br>Perhaps one of the most innovative addition to the Bill is the introduction of an appellate arbitration framework. This enables arbitral institutions to establish appellate tribunals with exclusive jurisdiction.</p>

          <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838", }}>The proposed changes demonstrate a thoughtful approach to addressing current challenges in the arbitration ecosystem. It is intended to balance the need for efficiency with due process, party autonomy, and modernization of the arbitration law.</p>

      <p style={{paddingTop: "1em",fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838",}}> <em><u>Important note</u> : Our Public Policy team is actively engaging with government stakeholders to convey our feedback on these proposed changes. We welcome suggestions and inputs from all interested parties to ensure that the suggestions effectively address the needs of the ODR ecosystem while maintaining the integrity and efficacy of the arbitration process.</em></p>
    </>
  )
}
