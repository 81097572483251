import React from 'react'

export default function Case10() {
  return (
    <>
          <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>

      
    <i style={{fontSize:  "17px"}}>Case Title: M/s Oravel Stays Pvt Ltd v. Nikhil Bhalla</i>        
    <i style={{fontSize:  "17px"}}>Court:  Delhi High Court</i>
    <i style={{fontSize:  "17px"}}>Date of Judgement:  23.04.2024</i>
          </div>
          
<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>In this case, the Hon’ble Delhi High Court dealt with cross-appeals arising from an order of a Commercial Court rejecting an application under Section 8 of the Arbitration and Conciliation Act, 1996 (the “Act”) filed to refer parties to arbitration in a suit.</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The key issue was whether the arbitration clause in the Terms and Conditions published on a website was incorporated into the Marketing and Operational Consulting Agreement (“MOCA”) between the parties by reference under a particular clause of the MOCA.</p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The Hon’ble Court held that the MOCA expressly incorporated the Terms and Conditions published on the website as part of the MOCA. It provided a hyperlink to access the Terms and Conditions, which included an arbitration clause.</p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The Court relied on Section 7(5) of the Act, which states that reference in a contract to a document containing an arbitration clause constitutes an arbitration agreement if the reference is such as to make that clause part of the contract.</p>       

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The Court rejected arguments that there was no specific reference to the arbitration clause, and that the hyperlink did not directly lead to the relevant Terms and Conditions. It held that the fact that the website had different terms for different categories of parties did not negate the incorporation by reference.</p>           

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>Applying the test laid down in the case of M.R. Engineers & Contractors Pvt. Ltd. v. Som Datt Builders Ltd2., the Court found that the clause in the MOCA evidenced an intention to incorporate the entire Terms and Conditions, including the arbitration clause, and not just refer to them.</p>          

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>Thus, the Court concluded that by virtue of the clause in the MOCA, the clause containing the arbitration agreement in the hyperlinked Terms and Conditions was incorporated.</p>
   </>
  )
}
