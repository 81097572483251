import React from 'react'
export default function Case2() {
  return (
    <>
    <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>

      
<i style={{fontSize:  "17px"}}>Case Title: VR Dakshin Private Limited vs Prime Store and Ors.</i>        
<i style={{fontSize:  "17px"}}>Court: Madras High Court</i>
<i style={{fontSize:  "17px"}}>Date of Judgement:  26.11.2024</i>
      </div>        


  <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>In an interesting development after the Constitution Bench judgment on the issue of Unilateral Appointment in Central Organisation for Railway Electrification v. ECI-SPIC-SMO-MCML (JV)1 (“CORE”), the Madras High Court in in the captioned case addressed crucial questions regarding challenges to arbitrator appointments and waiver of objections under the Arbitration and Conciliation Act, 1996 (“Act”). The dispute arose from a lease agreement where the lessee terminated the agreement during the lock-in period. Following the termination, the lessor appointed a former High Court judge as the sole arbitrator pursuant to the arbitration clause in the agreement.</p>   
         
          
<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>The significant aspect of the case was that the respondent participated in the arbitration proceedings without any objection to the arbitrator’s appointment and even joined in an application to extend the arbitrator’s mandate. However, after receiving an unfavorable award, the respondent challenged the arbitrator’s appointment under Section 34 of the Act, arguing that the unilateral appointment power was invalid based on the Supreme Court’s decisions in cases such as TRF Ltd v. Energo Engineering Projects Ltd (“TRF”) and Perkins Eastman Architects DPC and another v. HSCC (India) Ltd. (“Perkins”)</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>The Court had to primarily consider whether Section 12(5) of the Act was applicable and whether there was a valid waiver by the respondents. The Court held that Section 12(5) applies only when the arbitrator’s relationship falls under any category specified in the Seventh Schedule. Since there was no such relationship established, and notably no allegation of bias or partiality against the arbitrator, Section 12(5) and its proviso requiring express waiver were found inapplicable.</p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>The Court also distinguished the present case from TRF and Perkins judgments. The Court noted that in TRF, the case involved naming an employee as an arbitrator, which was materially different from the present situation where an independent former High Court judge was appointed. Moreover, in both TRF and Perkins, the objections to arbitrator appointment were raised during the arbitration proceedings itself, unlike the present case where no objections were raised throughout the proceedings. The Court emphasized that these precedents were primarily concerned with situations where there was a possibility of bias or impartiality.</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>The Court found this distinction crucial because in the present case, the respondents admittedly weren’t even alleging any bias or impartiality against the arbitrator. Notably, the respondent’s reliance on Perkins was found to be misplaced as that judgment was delivered after the arbitrator had entered reference in the present case, and even after the judgment was pronounced, the respondents continued to participate in the proceedings without objection and even consented in writing for continuation of the arbitrator.</p>   
         

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>The Court’s analysis of Section 12(5) was particularly noteworthy owing to its reference to the recent CORE judgment, especially the opinion which seems to be authored by Justice Hrishikesh Roy.</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>The appellant’s counsel cited this opinion of the judgment to emphasize that the Act does not per se prohibit unilateral appointment of arbitrators, and eligible arbitrators not otherwise disqualified under the Seventh Schedule can be appointed unilaterally.</p>           

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>Drawing from CORE, the Court emphasized that the proviso to Section 12(5) allows parties to waive its applicability after the dispute has arisen, thereby securing genuine party autonomy. The Court clarified that automatic disqualification of an arbitrator occurs only when it is established that the arbitrator falls under any category mentioned in the Seventh Schedule, without requiring investigation into actual bias. In such cases, since the ineligibility goes to the root of the appointment, parties can file an application under Section 14(2) for terminating the arbitrator's mandate. </p>

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>Significantly, the Court highlighted that in situations where the arbitrator’s relationship does not fall under the Seventh Schedule categories, there is no automatic disqualification. While parties may still raise justifiable doubts about the arbitrator’s independence or impartiality, they need only show possible doubts rather than demonstrate actual lack of independence or impartiality. <strong>However, in the present case, the arbitrator did not fall into any of the Seventh Schedule disqualifications, and the respondents never raised any doubts about the arbitrator’s independence or impartiality until the Section 34 petitions were filed. In fact, their counsel explicitly stated they did not doubt the arbitrator's independence or impartiality.</strong></p>

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>On the issue of waiver, the Court made two significant findings. First, it held that participation in arbitration proceedings without objection constitutes a deemed waiver under Section 4 of the Act. Second, the joint memo seeking extension of the arbitrator’s mandate amounted to express written consent. The Court emphasized that the Act does not prescribe any specific template for how a waiver should be worded.</p>         

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>In the context of the above, the Court set aside the Single Judge’s order and remanded the matter for consideration of other grounds under Section 34, making the payment of costs a condition precedent for pursuing the Section 34 petition.</p>           

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>This judgment significantly reinforces the principle that technical objections to arbitrator appointments cannot be raised belatedly, especially when parties have participated in proceedings without protest and where no actual bias or impartiality is alleged.</p>           
   </>
  )
}
