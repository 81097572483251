import React from 'react'

export default function Case24() {
  return (
    <>
     <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>
    <i style={{fontSize:  "17px"}}>Case Title: McLeod Russel India Ltd. v. Aditya Birla Finance Ltd</i>
    <i style={{fontSize:  "17px"}}>Court: Calcutta High Court</i>
    <i style={{fontSize:  "17px"}}>Date of Judgment: 14.02.2023</i>
</div>

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",color: "#383838"}}>The Hon’ble Calcutta High Court while deciding on a Section 14 application filed under the Arbitration and Conciliation Act, 1996, (“Act”) held that all unilateral appointment of arbitrators is not invalid unless the arbitrator’s relationship falls within the Seventh Schedule to the Arbitration and Conciliation Act, 1996.
</p>

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838"}}>The Hon’ble Court also stated that the disqualification of an arbitrator should not be solely based on the proviso to Section 12(5) and the Seventh Schedule of the Act. Hence, additional consideration must be given to any waiver of such disqualification that may have been explicitly agreed upon by the parties or inferred from their conduct post the dispute.
</p>
   </>
  )
}