import React from 'react'

export default function Case14() {
  return (
    <>
          <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>

      
    <i style={{fontSize:  "17px"}}>Case Title: Devender Kumar Kashyap vs Chander Muni.</i>        
    <i style={{fontSize:  "17px"}}>Court: Delhi High Court</i>
    <i style={{fontSize:  "17px"}}>Date of Judgement:  04.12.2023</i>
          </div>
          
<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>In a notable decision, the Hon’ble Delhi High Court addressed a jurisdictional issue involving the appointment of an arbitrator in a dispute arising from a partnership deed. The respondent objected to the petitioner's application under Section 11 of the Arbitration and Conciliation Act, 1996, (“Act”) contending that the invocation notice under Section 21 of the Act was not served at the correct address, rendering the petition premature.</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>The Hon’ble High Court rejected the respondent’s objection, observing that the respondent had himself provided the same address in a separate proceeding. The court clarified that the purpose of an invocation notice is to inform the opposite party about the claimant’s intent to invoke arbitration, and since the respondent was aware of the petitioner’s intention to pursue arbitration since 2021, the petition could not be deemed premature.</p>            

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>Distinguishing a cited case where the respondent was unaware of the invocation notice due to an incorrect address, the Hon’ble High Court upheld the validity of the invocation notice and appointed an arbitrator to adjudicate the disputes arising from the partnership deed.</p>
   </>
  )
}
