import React from 'react'

export default function Case19() {
  return (
    <>
       <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>
    <i style={{fontSize:  "17px"}}>Case Title:  In Re Interplay Between Arbitration Agreements Under The Arbitration And
    Conciliation Act 1996 And The Indian Stamp Act 1899.</i>
    <i style={{fontSize:  "17px"}}>Court:  Supreme Court of India</i>
    <i style={{fontSize:  "17px"}}>Date of Judgment: 13.12.2023</i>
</div>

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",color: "#383838" }}>The Supreme Court of India, led by a seven-judge bench, made a significant ruling on
December 13, 2023, declaring that arbitration clauses in unstamped or inadequately stamped agreements are enforceable. This decision overrules the earlier judgment by a five-judge
bench in the case of <em> M/s. N.N. Global Mercantile Pvt. Ltd. v. M/s. Indo Unique Flame Ltd</em>. And Ors, which held that unstamped arbitration agreements were not enforceable.</p>

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838" }}>The Hon’ble Court clarified that agreements lacking proper stamping are not void from the beginning i.e. void ab initio nor unenforceable; instead, they are simply inadmissible in evidence until the defect of non-stamping or inadequate stamping is cured. Additionally, the Hon’ble Court determined that objections regarding the stamping of agreements do not fall under the purview of Sections 8 or 11 of the Arbitration Act 1996, emphasizing that the courts must merely assess the prima facie existence of an arbitration agreement. Such stamping issues, the Hon’ble Court noted, fall within the jurisdiction of the arbitral tribunal.</p>
   </>
  )
}