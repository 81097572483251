import React from 'react'

export default function Case17() {
  return (
    <>
    <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>

 <i style={{fontSize:  "17px"}}>Case Title: Tata Motors Finance Solutions Limited vs Naushad Khan and Ors</i>
 <i style={{fontSize:  "17px"}}>Court: Bombay High Court</i>
 <i style={{fontSize:  "17px"}}>Date of Judgment: 20.12.2023</i>
</div>

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>
 
In a notable decision, the Hon’ble Bombay High Court addressed a fundamental jurisdictional
challenge in arbitration proceedings involving a financial institution. The respondents
contested the court’s jurisdiction to entertain petitions filed under Section 9 of the Arbitration
and Conciliation Act, 1996, and an application under Section 11, arguing that as a “financial
institution” under the SARFAESI Act, the petitioner should proceed under the SARFAESI Act,
barring the recourse to arbitration.</p>

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838" }}>


The Hon’ble High Court distinguished the definitions of 'financial institution' under the
SARFAESI Act and the Recovery of Debts Due to Banks and Financial Institutions Act, 1993
(“RDDB”), noting that being notified under the SARFAESI Act allows the petitioner to approach
the Debt Recovery Tribunal only for enforcement purposes. The court therefore rejected the
jurisdictional objection, upholding the arbitration agreement’s validity. It clarified that the
SARFAESI Act, focusing on enforcement, does not preclude the determination of debt due
through arbitration.</p>


</>
  )
}