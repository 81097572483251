import React from 'react'

export default function Case21() {
  return (
    <>
    <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>
    <i style={{fontSize:  "17px"}}>Case Title: Asset Reconstruction Company India Ltd v. ATS Infrastructure Limited, ARB. A.
    (COMM) 7 of 2022</i>
    <i style={{fontSize:  "17px"}}>Court: Bombay High Court</i>
    <i style={{fontSize:  "17px"}}>Date of Judgment: 20.11.2023</i>
</div>

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",color: "#383838" }}>The Hon’ble Delhi High Court ruled that an arbitral tribunal cannot create security on a property
over which a charge is already created in favour of a third party. This decision came after the tribunal, under Section 17 of the Arbitration and Conciliation Act (“A&C Act”), issued an interim
order creating security over a property. The appellant, who was not a party to the arbitration agreement, challenged this order, arguing that they had a pre-existing, registered charge on
the property. The respondents claimed they had invested in projects with secured interests prior to the appellant's charge.</p>    

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500",color: "#383838" }}>However, the Court held that the respondent failed to prove that they had any charge on the property and that they were secured creditors. Therefore, the appellant, with a registered charge, was deemed a secured creditor, holding a preferential position over the unsecured respondent. Consequently, the High Court modified the tribunal's order, confirming the arbitral tribunal’s lack of jurisdiction to impose security on the property due to the existing charge in favour of the appellant.</p>
   </>
  )
}