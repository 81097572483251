import React from 'react'

export default function Case3() {
  return (
    <>
  <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The 5-Judge Constitution Bench of the Supreme Court of India, in Central Organisation for Railway Electrification v. M/s ECI SPIC SMO MCML (JV), struck down unilateral appointment of arbitrators, for being violative of the principles of fairness, impartiality and equality in arbitration proceedings.</p>   
         
          
<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}><b style={{fontSize:  "17px"}}>This judgment reinforces institutional arbitration as the solution for agreements which have unilateral appointment clauses and highlights the significance of adopting an unambiguous named institutional arbitration clause in all prospective agreements.</b> The importance of opting for institutional arbitration is also supported by the <u>proposed amendments in the Draft Arbitration and Conciliation (Amendment) Bill, 2024.</u></p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}><u>Key highlights and implications :</u><br></br>
<u><b style={{fontSbize:  "17px"}}><em>Observation:</em></b></u> Equal treatment of parties enshrined in Section 18 of the Arbitration and Conciliation Act, 1996 (“Act”) applies to all stages of arbitration proceedings, including the appointment of arbitrators. Therefore, one party cannot unilaterally appoint a sole arbitrator as it gives rise to justifiable doubts as to the independence and impartiality of the arbitrator.</p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}><u><b style={{fontSize:  "17px"}}><em>Analysis:</em></b></u> As per the 2015 amendments to the Act followed by the law laid down in TRF Ltd., Perkins Eastman, numerous other rulings that follow the ratio therein, and finally as authoritatively held by the Constitution Bench in the extant judgment, it is clear that unilateral appointment of arbitrators is not permissible.</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}><u><b style={{fontSize:  "17px"}}><em>Conclusion:</em></b></u> Justice U. U. Lalit, Former Chief Justice of India and the author of Perkins Eastman, in an expert legal opinion sought by Presolv360, after perusing the appointment process of an arbitrator in arbitrations administered by Presolv360, opined that, “… it cannot be said that when the arbitrator was assigned by the Querist [Presolv360] after following the aforementioned steps that the arbitrator was unilaterally appointed or is ineligible to preside over the dispute in terms of Section 12(5) of the Act.”</p>   
         
 <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>He further opined, <em>“… if disputes arising out of agreements containing unilateral arbitrator appointment clauses are referred to an ADR institution/Centre such as the Querist [Presolv360], who assigns the dispute to an independent arbitrator from its panel, such cases are not hit by the ratio laid down in TRF (supra), and Perkins (supra) and other rulings which follow the ratio laid down therein.”</em></p> 

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}><b style={{fontSize:  "17px"}}><u>Accordingly, in an arbitration administered by a credible independent institution, where an arbitrator is appointed by the said institution, the appointment will be valid and will not be considered as a unilateral appointment.</u></b></p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}><em><u>Observation:</u></em> Proviso to Section 12(5) of the Act which contemplates express waiver against allegations of bias also applies to allegations of bias against an arbitrator appointed unilaterally by one of the parties.</p>           

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}><em><u>Analysis:</u></em> In a scenario where one party unilaterally appoints an arbitrator, the other party, subsequent to disputes having arisen, must waive the allegation of bias against the unilaterally appointed arbitrator by an express agreement in writing for the appointment to remain valid.</p>

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}><em><u>Conclusion:</u></em> As opined by Justice U. U. Lalit, where an arbitration is referred to an independent institution, and the arbitrator is appointed by such institution, the same will not be considered as unilateral appointment of the arbitrator, and such appointment will not fall within the scope of Section 12(5) read with the Seventh Schedule to the Act.</p>

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}><b style={{fontSize:  "17px"}}><u>Accordingly, in an arbitration administered by a credible independent institution, where an arbitrator is appointed by the said institution, the appointment will be valid and will not be considered as a unilateral appointment.</u></b></p>         

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}><u>Other observations:</u><br></br>
1.One party cannot mandate the other party to select an arbitrator from a list curated by the former party. This necessitates referring disputes to an independent institution for administering the arbitration proceedings and appointing an arbitrator from a broad-based panel of arbitrators maintained by it.<br></br>
2.In public-private contracts, unilateral appointments breach Article 14 of the Constitution of India, which guarantees equality.<br></br>
3.In scenarios where the arbitral tribunal consists of 3 arbitrators, the directions in the extant judgment will apply prospectively.</p>           

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}><u>Summary</u><br></br>
The judgment of the Constitution Bench of the Supreme Court of India fortifies the following:<br></br>
a) Appointment of an arbitrator by one party is prohibited;<br></br>
b) Arbitrations administered by a neutral institution through an independent arbitrator appointed by such institution will not be considered as unilateral appointment and does not require express consent by the other party;<br></br>
c) Parties must adopt an unambiguous named institutional arbitration clause in all prospective agreements. Click here for a model arbitration clause</p>            
          
<p style={{ paddingTop: "1em", paddingBottom: "2em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}><b style={{fontSize:  "17px"}}><u>The above approach was also suggested by the Solicitor General of India to the Constitution Bench as a preferred model for resolving disputes via arbitration.</u></b></p>           
   </>
  )
  
}
