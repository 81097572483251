import React from 'react'

export default function Case7() {
  return (
    <>
    <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>

      
    <i style={{fontSize:  "17px"}}>Case Title:  Gujarat Enterprises Vs. UGRO Capital Limited</i>        
    <i style={{fontSize:  "17px"}}>Court: Delhi Commercial Court</i>
    <i style={{fontSize:  "17px"}}>Date of Judgement: 27.07.2024</i>
          </div>
          
<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>A Delhi Commercial Court has dismissed objections filed under Section 34 of the Arbitration and Conciliation Act, 1996 (“Act”) challenging an arbitral award passed by an arbitrator appointed by Presolv360. The objections were based on alleged unilateral appointment of the arbitrator and lack of service of notice under Section 21 of the Act to the petitioners.</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The petitioners contended that the arbitrator was unilaterally appointed by the respondent. However, the court concluded that the respondent approached an independent ODR institution, Presolv360, which selected the arbitrator from its panel. The arbitrator duly provided a declaration under Section 12 of the Act, and his appointment by the institution did not violate Section 12(5) of the Arbitration and Conciliation Act.</p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The court observed:<br></br>
<em>“The petitioners have claimed that it is unilateral appointment of the Arbitrator. I am of the considered opinion that it is not the respondent who chose the arbitrator for conducting the arbitral proceedings. The respondent approached an independent ODR institution Presolv360 and this institution selected one arbitrator from its panel. The arbitrator duly gave declaration u/s 12 of the Act as required and his appointment by the Institution is not hit by Section 12 (5) of the Arbitration and Conciliation Act. It is not a case where respondent no. 1 appointed the arbitrator itself.”</em></p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>Furthermore, the court found that all notices, including those under Section 21 of the Act, were duly served to the petitioners through email and WhatsApp. The petitioners had claimed they did not receive notice of the arbitral proceedings, but the court noted:</p> 

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}><em>“The record filed by the respondent no. 1 clearly shows that the Award was delivered to the petitioners through WhatsApp on 13.05.2023 at 09.11.14 hours and the same was read immediately. Record also shows that all the notices sent to the petitioners were duly delivered to them through e-mail as well as through WhatsApp and were also read simultaneously. The petitioners, therefore, cannot claim that they did not have any knowledge of the arbitral proceedings or that notice U/s 21 of the Act or of the arbitral proceedings were not served on the petitioners.”</em></p>  

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>The court emphasized the validity of the arbitration agreement and the proper notice under Section 21 of the Act. It concluded that the arbitral award was passed by a neutral person, and no grounds existed under Section 34 of the Arbitration and Conciliation Act to set aside the award. Consequently, the objections were dismissed.</p>           


<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>This ruling underscores the judicial stance against objections based on allegations of unilateral appointment when an institutional mechanism is involved. It also highlights the importance of participating in arbitral proceedings and the validity of electronic service of notices.</p>           
   </>
  )
}
