
import React from 'react'
export default function Case1() {
  return (
<>
    <div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>

      
<i style={{fontSize:  "17px"}}>Case Title:  M/S Vidyawati Construction Company vs. Union of India, 2025 INSC 101</i>        
<i style={{fontSize:  "17px"}}>Court: Supreme Court of India</i>
<i style={{fontSize:  "17px"}}>Date of Judgement:   07.01.2025</i>
      </div>        


  <p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500",  color: "#383838" }}>Section 16 of the Act, in line with the kompetenz-kompetenz principle, provides that the arbitral tribunal is competent to rule on its jurisdiction. The intent of the said provision is to ensure minimal court intervention in arbitration proceedings. While Section 16 of the Act allows parties to challenge the jurisdiction of an arbitral tribunal, sub-section (2) bars a party from raising such a challenge after submission of the statement of defence.</p>   
         
          
<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500",  color: "#383838" }}>In the instant matter, the statement of defence was filed by the Respondent before the erstwhile arbitral tribunal. Subsequently, the arbitral tribunal was reconstituted, and the arbitrator was appointed by the Apex Court.</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>The Respondent had agreed to file its earlier statement of defence before the newly constituted tribunal. The Respondent later sought an extension to file a modified statement of defence. However, no application was moved for modification or amendment. The Respondent instead filed an application challenging the jurisdiction of the arbitral tribunal.</p>     

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>The arbitral tribunal disallowed the application noting that the Respondent had expressly submitted to its jurisdiction. Further it was observed that the application was barred under Section 16(2) as it was filed after filing of the statement of defence. Subsequently, an award was passed in the matter.</p>   

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>In a challenge under the Act, the District Court set aside the award and the High Court upheld the order of the District Court.</p>   
                

<p style={{ paddingTop: "1em",paddingBottom:"2em", fontFamily: "Montserrat", fontSize:  "17px", fontWeight: "500", color: "#383838" }}>The Supreme Court, while setting aside the judgments of the District Court and High Court, affirmed the principle that the jurisdiction of the arbitral tribunal cannot be challenged after the submission of the statement of defence.</p>           
   </>
  )
}
