import React from 'react'

export default function Case28() {
  return (
    <>

<div style={{ fontFamily: "Montserrat", color: "#383838", fontWeight: "400" }}>
    <i style={{fontSize:  "17px"}}>Case Title: Ministry of Youth Affairs and Sports v. Ernst and Young Pvt Ltd1</i>
    <i style={{fontSize:  "17px"}}>Court: Delhi High Court</i>
    <i style={{fontSize:  "17px"}}>Date of Judgment: 23.08.2023</i>
</div>

<p style={{ paddingTop: "1em", fontFamily: "Montserrat", fontSize:  "17px",fontWeight: "500", color: "#383838"}}>
    In a recent decision, the Delhi High Court stated that the delivery of a scanned, signed copy of <b style={{fontSize:  "17px"}}> an arbitral award via email falls squarely within the ambit of valid delivery as stipulated under Section 31(5) of the Arbitration and Conciliation Act, 1996.</b> 
    This decision clearly delineates the legal standing of electronic delivery methods in arbitration proceedings.
</p>

<p style={{ paddingTop: "1em", paddingBottom:"2em", fontFamily: "Montserrat",fontSize:  "17px",fontWeight: "500", color: "#383838" }}>
    The court specifically stated that “The law has to keep its pace in tandem with the developing technology. 
    <b style={{fontSize:  "17px", color: "#383838"}}>When service by email is an accepted mode of service, then sending a scanned signed copy of the award/order of the Arbitral Tribunal to the parties would be a valid delivery </b>as envisaged under Section 31(5) of the Arbitration Act.”
</p>

</>  
  )
}